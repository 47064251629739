import { t } from '@zupr/i18n'
import classnames from 'classnames'
import Link from 'next/link'
import React from 'react'

import { useDomain } from '../../../../context/domain'
import { useIsMobile } from '../../../../context/ux'

import { ReactComponent as Bee } from './bee.svg'

import '../../../../../scss/react/pages/big-header.scss'
import styles from './beezy.module.scss'

const BeezyHeader = () => {
    const { entrepreneurStories, description, brand } = useDomain()

    const isMobile = useIsMobile()

    return (
        <div className={classnames('big-header', styles['header'])}>
            <div
                className={classnames('big-header-content', styles['content'])}
            >
                <div className={styles['slogan']}>
                    <Bee />
                    <p>
                        {(
                            description ||
                            `je\nwinkel\nstraat\nheeft een\nbood\nschap`
                        )
                            .split('\n')
                            .map((word) => (
                                <React.Fragment key={word}>
                                    {word}
                                    <br />
                                </React.Fragment>
                            ))}
                    </p>
                    <h1>{brand}</h1>
                </div>

                <div
                    className={classnames({
                        'desktop-links': !isMobile,
                        'mobile-links': isMobile,
                    })}
                >
                    <ul>
                        <li>
                            <Link href="/producten">
                                <a>{t('Products')}</a>
                            </Link>
                        </li>
                        <li>
                            <Link href="/winkels">
                                <a>{t('Stores')}</a>
                            </Link>
                        </li>
                        {!!entrepreneurStories?.length && (
                            <li>
                                <Link href="/ondernemers">
                                    <a>{t('Entrepreneurs')}</a>
                                </Link>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default BeezyHeader
